html {
  font-size: 16px;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
html {
  font-family:
    Inter,
    system-ui,
    sans-serif;
  color: #000000;
}
body[data-no-scroll=true] {
  overflow: hidden;
}
.dark-theme {
  color: #e9e9ea;
}
.body-spacious-strong-small {
  font-size: 0.875rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: -0.00875rem;
  line-height: 1.5rem;
}
@media (max-width: 768px) {
  .s-body-spacious-strong-small {
    font-size: 0.875rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: -0.00875rem;
    line-height: 1.5rem;
  }
}
.body-spacious-strong-medium {
  font-size: 1rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: -0.02rem;
  line-height: 1.75rem;
}
@media (max-width: 768px) {
  .s-body-spacious-strong-medium {
    font-size: 1rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: -0.02rem;
    line-height: 1.75rem;
  }
}
.body-spacious-strong-large {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: -0.0225rem;
  line-height: 1.875rem;
}
@media (max-width: 768px) {
  .s-body-spacious-strong-large {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: -0.0225rem;
    line-height: 1.875rem;
  }
}
.body-spacious-small {
  font-size: 0.875rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: -0.00875rem;
  line-height: 1.5rem;
}
@media (max-width: 768px) {
  .s-body-spacious-small {
    font-size: 0.875rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 400;
    letter-spacing: -0.00875rem;
    line-height: 1.5rem;
  }
}
.body-spacious-medium {
  font-size: 1rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: -0.02rem;
  line-height: 1.75rem;
}
@media (max-width: 768px) {
  .s-body-spacious-medium {
    font-size: 1rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 400;
    letter-spacing: -0.02rem;
    line-height: 1.75rem;
  }
}
.body-spacious-large {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: -0.0225rem;
  line-height: 1.875rem;
}
@media (max-width: 768px) {
  .s-body-spacious-large {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 400;
    letter-spacing: -0.0225rem;
    line-height: 1.875rem;
  }
}
.body-strong-large {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: -0.0225rem;
  line-height: 1.625rem;
}
@media (max-width: 768px) {
  .s-body-strong-large {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: -0.0225rem;
    line-height: 1.625rem;
  }
}
.body-strong-medium {
  font-size: 1rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: -0.02rem;
  line-height: 1.5rem;
}
@media (max-width: 768px) {
  .s-body-strong-medium {
    font-size: 1rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: -0.02rem;
    line-height: 1.5rem;
  }
}
.body-strong-small {
  font-size: 0.875rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: -0.00875rem;
  line-height: 1.25rem;
}
@media (max-width: 768px) {
  .s-body-strong-small {
    font-size: 0.875rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 600;
    letter-spacing: -0.00875rem;
    line-height: 1.25rem;
  }
}
.body-large {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: -0.0225rem;
  line-height: 1.625rem;
}
@media (max-width: 768px) {
  .s-body-large {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 400;
    letter-spacing: -0.0225rem;
    line-height: 1.625rem;
  }
}
.body-medium {
  font-size: 1rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: -0.02rem;
  line-height: 1.5rem;
}
@media (max-width: 768px) {
  .s-body-medium {
    font-size: 1rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 400;
    letter-spacing: -0.02rem;
    line-height: 1.5rem;
  }
}
.body-small {
  font-size: 0.875rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: -0.00875rem;
  line-height: 1.25rem;
}
@media (max-width: 768px) {
  .s-body-small {
    font-size: 0.875rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 400;
    letter-spacing: -0.00875rem;
    line-height: 1.25rem;
  }
}
.headings-900 {
  font-size: 4rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: -0.08rem;
  line-height: 4.5rem;
}
@media (max-width: 768px) {
  .s-headings-900 {
    font-size: 4rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 700;
    letter-spacing: -0.08rem;
    line-height: 4.5rem;
  }
}
.headings-800 {
  font-size: 3rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: -0.06rem;
  line-height: 3.5rem;
}
@media (max-width: 768px) {
  .s-headings-800 {
    font-size: 3rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 700;
    letter-spacing: -0.06rem;
    line-height: 3.5rem;
  }
}
.headings-700 {
  font-size: 2.5rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: -0.05rem;
  line-height: 3rem;
}
@media (max-width: 768px) {
  .s-headings-700 {
    font-size: 2.5rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 700;
    letter-spacing: -0.05rem;
    line-height: 3rem;
  }
}
.headings-600 {
  font-size: 2rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: -0.04rem;
  line-height: 2.5rem;
}
@media (max-width: 768px) {
  .s-headings-600 {
    font-size: 2rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 700;
    letter-spacing: -0.04rem;
    line-height: 2.5rem;
  }
}
.headings-500 {
  font-size: 1.5rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: -0.03rem;
  line-height: 2rem;
}
@media (max-width: 768px) {
  .s-headings-500 {
    font-size: 1.5rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 700;
    letter-spacing: -0.03rem;
    line-height: 2rem;
  }
}
.headings-400 {
  font-size: 1.125rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: -0.0225rem;
  line-height: 1.625rem;
}
@media (max-width: 768px) {
  .s-headings-400 {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 700;
    letter-spacing: -0.0225rem;
    line-height: 1.625rem;
  }
}
.headings-300 {
  font-size: 1rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 700;
  letter-spacing: -0.02rem;
  line-height: 1.5rem;
}
@media (max-width: 768px) {
  .s-headings-300 {
    font-size: 1rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 700;
    letter-spacing: -0.02rem;
    line-height: 1.5rem;
  }
}
.pointer {
  cursor: pointer;
}
.shadow-500 {
  box-shadow: 0px 10px 64px 0px #0000007a;
  outline: 0;
}
.shadow-400 {
  box-shadow: 0px 8px 48px 0px #00000052;
  outline: 0;
}
.shadow-300 {
  box-shadow: 0px 6px 32px 0px #00000029;
  outline: 0;
}
.shadow-200 {
  box-shadow: 0px 4px 16px 1px #00000014;
  outline: 0;
}
.shadow-100 {
  box-shadow: 0px 2px 2px 0px #0000000f;
  outline: 0;
}
.focus-danger {
  box-shadow: 0px 0px 0px 4px #f7d7ddff;
  outline: 0;
}
.focus-primary {
  box-shadow: 0px 0px 0px 4px #c9c9f5ff;
  outline: 0;
}
@media (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }
}
.hide-desktop {
  display: none !important;
}
@media (max-width: 768px) {
  .hide-desktop {
    display: unset !important;
  }
}
.button {
  font-size: 1rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 600;
  letter-spacing: -0.02rem;
  line-height: 1.5rem;
  width: fit-content;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 999px;
  border: 1px solid #4b3bff;
}
.button:hover {
  text-decoration: none;
}
.button[data-variant=primary] {
  background: #4b3bff;
  color: #ffffff;
}
.button[data-variant=primary]:hover {
  background: #3018cc;
  cursor: pointer;
}
.button[data-variant=primary-white] {
  background: #ffffff;
  color: #4b3bff;
}
.button[data-variant=primary-white]:hover {
  cursor: pointer;
}
.button[data-variant=secondary] {
  background: #ffffff;
  color: #4b3bff;
  border: 2px solid #4b3bff;
}
.dark-theme .button[data-variant=secondary] {
  background: transparent;
  color: #ffffff;
}
.button[data-variant=secondary]:hover {
  color: #3018cc;
  cursor: pointer;
}
.dark-theme .button[data-variant=secondary]:hover {
  color: #e9e9ea;
}
.button[data-variant=secondary-white] {
  background: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
}
.button[data-variant=secondary-white]:hover {
  color: #ffffff;
  cursor: pointer;
}
.button[data-variant=tertiary] {
  background: transparent;
  color: #4b3bff;
  border: 2px solid transparent;
}
.dark-theme .button[data-variant=tertiary] {
  color: #ffffff;
}
.button[data-variant=tertiary]:hover {
  color: #3018cc;
  background: #f4f4f5;
  cursor: pointer;
}
.dark-theme .button[data-variant=tertiary]:hover {
  color: #f4f4f5;
  background: #50505e;
}
.button[data-variant=tertiary][data-black=true] {
  color: #000000;
}
.dark-theme .button[data-variant=tertiary][data-black=true] {
  color: #ffffff;
}
.button[data-variant=danger] {
  background: #c91737;
  color: #ffffff;
  border: 2px solid #c91737;
}
.button[data-variant=danger]:hover {
  background: #8e1027;
  cursor: pointer;
}
.button[data-size=large] {
  height: 56px;
  padding: 20px 32px;
}
.button[data-size=large][data-icon=true] {
  padding: 16px;
  width: 54px;
}
.button[data-size=medium] {
  height: 46px;
  padding: 12px 20px;
}
.button[data-size=medium][data-icon=true] {
  padding: 12px;
  width: 46px;
}
.button[data-size=small] {
  height: 38px;
  padding: 8px 12px;
}
.button[data-size=small][data-icon=true] {
  padding: 7px;
  width: 38px;
}
.button svg:first-child {
  margin-right: 8px;
}
.button svg:last-child {
  margin-left: 8px;
}
.button[data-icon=true] svg {
  margin: 0;
}
.button[data-fill=true] {
  width: 100%;
}
@media (max-width: 768px) {
  .button[data-fill=mobile] {
    width: 100%;
  }
}
.text-input {
  width: 375px;
  max-width: 100%;
}
.text-input[data-fill=true] {
  width: 100%;
}
@media (max-width: 768px) {
  .text-input[data-fill=mobile] {
    width: 100%;
  }
}
.text-input--container {
  position: relative;
  height: 60px;
}
.text-input--label {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px 14px 16px;
  color: #50505e;
  pointer-events: none;
  transition: all 0.15s;
}
.dark-theme .text-input--label {
  color: #ffffff;
}
.text-input:focus-within .text-input--label,
.text-input[data-filled=true] .text-input--label {
  font-size: 0.875rem;
  text-decoration: none;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: -0.00875rem;
  line-height: 1.25rem;
  padding-top: 20px;
  height: 8px;
}
.text-input[data-disabled=true] .text-input--label {
  color: #cfcfd1;
}
.dark-theme :is(.text-input[data-disabled=true] .text-input--label) {
  color: #a9a9af;
}
.text-input[data-error=true] .text-input--label {
  color: #c91737;
}
.text-input--input {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #cfcfd1;
  border-radius: 4px;
  padding: 16px 16px 14px;
  margin: 0;
  color: #131215;
  background: transparent;
}
.dark-theme .text-input--input {
  color: #ffffff;
  background: #383945;
}
.text-input--input::placeholder {
  color: #bbbbbf;
}
.text-input--input:not(:focus)::placeholder {
  color: transparent;
}
.text-input--input:focus,
.text-input[data-filled=true] .text-input--input {
  padding-top: 22px;
  padding-bottom: 4px;
}
.text-input[data-disabled=true][data-filled=true] .text-input--input {
  color: #cfcfd1;
  -webkit-text-fill-color: #cfcfd1;
}
.dark-theme :is(.text-input[data-disabled=true][data-filled=true] .text-input--input) {
  color: #50505e;
  -webkit-text-fill-color: #50505e;
}
.text-input[data-error=true] .text-input--input {
  border: 1px solid #c91737;
  color: #c91737;
}
.text-input[data-error=true] .text-input--input:focus-visible {
  border: 1px solid #4b3bff;
}
.field-error {
  display: flex;
  align-items: flex-start;
  color: #c91737;
  padding-top: 8px;
}
.field-error svg {
  flex: 0 0 20px;
  height: 20px;
  width: 20px;
  margin-right: 6px;
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}
main {
  flex: 1 1 100%;
  width: 100%;
  max-width: 1128px;
  padding: 0 32px;
  margin: 64px auto;
  color: #131215;
}
h1 {
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 40px;
  margin-bottom: 12px;
}
.document-content h1 {
  margin-bottom: 40px;
}
.document-content h2 {
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 40px;
  margin: 32px 0 16px;
}
.document-content h3 {
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 32px;
  margin: 24px 0 16px;
}
.document-content p {
  margin-bottom: 16px;
}
.document-content ul {
  margin: 16px 0 32px;
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 24px;
}
.document-content ol {
  margin: 16px 0 32px;
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 24px;
}
.document-content ul ul,
.document-content ol ul {
  list-style-type: circle;
  list-style-position: outside;
  margin-left: 0 0 0 16px;
}
.document-content ol ol,
.document-content ul ol {
  list-style-type: lower-latin;
  list-style-position: outside;
  margin: 0 0 0 16px;
}
.document-content table {
  border-collapse: collapse;
  margin: 32px 0;
}
.document-content th,
.document-content td {
  border: 1px solid #000000;
  padding: 4px;
}
.document-content hr {
  border: 0;
  border-top: 1px solid #e9e9ea;
  margin: 40px 0;
}
.document-content img,
.document-content video {
  display: block;
  margin: 32px auto;
  border: 4px solid #dfdfe1;
  border-radius: 16px;
  max-width: 100%;
  height: auto;
}
.document-content blockquote {
  background: #e9e9ea;
  padding: 16px;
  margin: 16px 0;
  font-style: italic;
  border-radius: 4px;
}
.error-page {
  min-height: 40vh;
  max-width: inherit;
  width: fit-content;
  text-align: center;
}
@media (max-width: 768px) {
  .error-page {
    width: 100%;
    max-width: 100%;
  }
}
.header {
  z-index: 10;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: #4b3bff;
  height: 80px;
  padding: 24px 32px;
}
@media (max-width: 768px) {
  .header {
    padding: 20px;
  }
}
.header[data-variant=white] {
  color: #000000;
  background: #ffffff;
}
.header[data-variant=white] a:not(.button) {
  color: #000000;
}
.header--logo {
  transition: opacity 0.3s ease;
}
@media (max-width: 768px) {
  .header--logo {
    flex: 1;
  }
}
@media (max-width: 768px) {
  .header[data-open=true] .header--logo {
    pointer-events: none;
    opacity: 0;
  }
}
.header a {
  color: #ffffff;
  text-decoration: none;
}
.header a:hover {
  text-decoration: none;
}
.header--title {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;
  text-decoration: none;
  margin-right: 90px;
}
.header--title svg {
  margin-right: 12px;
}
.header--nav {
  flex: 1;
}
@media (max-width: 768px) {
  .header--nav {
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
  }
  .header[data-open=true] .header--nav {
    display: flex;
    pointer-events: all;
  }
}
.header--navwrap {
  display: flex;
  align-items: center;
  flex: 1;
}
@media (max-width: 768px) {
  .header--navwrap {
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: absolute;
    left: 100%;
    right: -100%;
    top: 0;
    bottom: 0;
    transition: all 0.3s;
  }
  .header[data-variant=purple] .header--navwrap {
    background: #4b3bff;
  }
}
.header[data-open=true] .header--navwrap {
  left: 0;
  right: 0;
}
.header--nav-left {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 768px) {
  .header--nav-left {
    width: 100%;
    flex-direction: column;
    gap: 0;
    padding: 20px;
  }
}
.header--nav-left a {
  display: block;
}
@media (max-width: 768px) {
  .header--nav-left a {
    padding: 16px 0;
  }
}
.header--nav-left a[data-current=true] {
  font-weight: 600;
}
@media (max-width: 768px) {
  .header--nav-left a[data-current=true] {
    font-weight: 700;
  }
}
.header--nav-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}
@media (max-width: 768px) {
  .header--nav-right {
    gap: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px 20px;
  }
}
@media (max-width: 768px) {
  .header--nav-start-desktop {
    display: none;
  }
}
.header--nav-start-mobile {
  display: none;
}
@media (max-width: 768px) {
  .header--nav-start-mobile {
    display: flex;
  }
}
.footer {
  width: 100%;
  background: #4b3bff;
  color: #ffffff;
}
.footer[data-compact=true] {
  background: transparent;
  color: #000000;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .footer[data-compact=true] {
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .footer {
    padding: 0 20px 20px;
  }
}
.footer p {
  text-align: center;
  margin-bottom: 32px;
}
.footer--top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 122px;
  margin-bottom: 122px;
}
@media (max-width: 768px) {
  .footer--top {
    padding: 78px 32px 0;
    margin-bottom: 72px;
  }
}
.footer--get-started {
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  letter-spacing: -0.02em;
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  .footer--get-started {
    font-size: 3rem;
    margin-bottom: 12px;
  }
}
.footer--bottom {
  margin: 32px 32px 0;
}
@media (max-width: 768px) {
  .footer--bottom {
    margin: 32px 0 0;
  }
}
.footer--bottom a {
  color: #ffffff;
  text-decoration: none;
}
[data-compact=true] :is(.footer--bottom a) {
  color: #000000;
}
.footer--bottom a:hover {
  text-decoration: none;
}
.footer--bottom a:focus {
  text-decoration: underline;
}
.footer--primary-nav {
  padding: 32px 0;
}
@media (max-width: 768px) {
  .footer--primary-nav {
    border-bottom: 1px solid #c9c9f5;
  }
}
.footer--primary-nav a {
  color: #ffffff;
  text-decoration: none;
  margin-right: 32px;
}
.footer--bottom-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
@media (max-width: 768px) {
  .footer--bottom-row {
    margin-top: 24px;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}
@media (max-width: 768px) {
  .footer--bottom-row-primary {
    width: 100%;
    margin-bottom: 16px;
  }
}
hamburger-icon {
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 4.8px;
}
@media (max-width: 768px) {
  hamburger-icon {
    display: flex;
  }
}
.hamburger--bar {
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background: #000000;
}
[data-open=true] .hamburger--bar {
  transition: all 0.3s ease;
}
hamburger-icon[data-white=true] .hamburger--bar {
  background: #ffffff;
}
[data-open=true] .hamburger--bar:first-child {
  transform: translateY(6.8px) rotate(45deg);
}
[data-open=true] .hamburger--bar:last-child {
  transform: translateY(-6.8px) rotate(-45deg);
}
[data-open=true] .hamburger--bar:nth-child(2) {
  opacity: 0;
}
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #4b3bff;
}
.hero h1 {
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  letter-spacing: -0.02em;
  line-height: 4rem;
  margin: 48px 0 12px;
}
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.25rem;
    letter-spacing: -0.01em;
    line-height: 2.5rem;
    margin: 24px 0 8px;
  }
}
.hero p {
  margin-bottom: 32px;
}
.hero a {
  margin-bottom: 48px;
}
.hero--col {
  width: 100%;
  max-width: 1128px;
  padding: 0 32px;
}
.hero-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background:
    linear-gradient(
      180deg,
      #4b3bff 50%,
      #ffffff 50%);
}
.hero-image img {
  width: 100%;
  height: auto;
  border: 8px solid #6c7bff;
  border-radius: 20px;
  background: #ffffff;
}
.hero-image--col {
  width: 100%;
  max-width: 1128px;
  padding: 0 32px;
}
@media (max-width: 768px) {
  .hero-image--col {
    padding: 0 20px;
  }
}
.home-page {
  width: 100%;
  max-width: 1128px;
  padding: 0 32px;
  margin: 104px auto;
  color: #131215;
}
@media (max-width: 768px) {
  .home-page {
    padding: 0 20px;
    margin: 48px auto 93px;
  }
}
.home-page h2 {
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  letter-spacing: -0.02em;
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 700;
}
@media (max-width: 768px) {
  .home-page h2 {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.01em;
  }
}
.home-page img {
  width: 100%;
  height: auto;
  border: 8px solid #000000;
  border-radius: 20px;
  background: #ffffff;
}
.home-page hr {
  margin: 88px 0;
  border: 0;
  border-top: 1px solid #e9e9ea;
}
@media (max-width: 768px) {
  .home-page hr {
    width: 64px;
    margin: 72px auto;
    border-top: 4px solid #dfdfe1;
  }
}
.home-page--testimonials h2 br {
  display: none;
}
@media (max-width: 768px) {
  .home-page--testimonials h2 br {
    display: inline;
  }
}
.home-page--testimonial-bio {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
}
.home-page--testimonial-bio img {
  border: 0;
  width: 64px;
  height: 64px;
  background: #2f2f38;
  border-radius: 50%;
}
.home-page--testimonial-top {
  padding-top: 40px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 40px;
}
@media (max-width: 768px) {
  .home-page--testimonial-top {
    font-size: 1.125rem;
    text-decoration: none;
    font-family: Inter;
    font-weight: 400;
    letter-spacing: -0.0225rem;
    line-height: 1.875rem;
  }
}
.home-page--testimonial-split {
  display: flex;
  flex-direction: row;
  column-gap: 56px;
  row-gap: 56px;
  margin-top: 56px;
}
@media (max-width: 768px) {
  .home-page--testimonial-split {
    flex-direction: column;
  }
}
.home-page--testimonial-split > div {
  flex: 1 1 50%;
}
.home-page--testimonial-split > div p {
  color: #383945;
}
.home-page--testimonial-split > div p:not(:first-child) {
  margin-top: 32px;
}
.your-work {
  display: flex;
}
@media (max-width: 768px) {
  .your-work {
    flex-direction: column;
  }
}
.your-work--left {
  padding-right: 114px;
  margin-right: 114px;
  border-right: 1px solid #dfdfe1;
  flex-basis: 60%;
}
@media (max-width: 768px) {
  .your-work--left {
    padding: 0;
    margin: 0;
    border-right: 0;
  }
}
.your-work--left h2 {
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  .your-work--left h2 {
    margin-bottom: 8px;
  }
}
.your-work--left p {
  margin-bottom: 24px;
  color: #383945;
}
@media (max-width: 768px) {
  .your-work--left p {
    margin-bottom: 32px;
  }
}
.your-work--right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-basis: 40%;
}
.your-work--right h3 {
  margin-bottom: 4px;
}
.your-work--right p {
  color: #383945;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .your-work--right p {
    margin-bottom: 40px;
  }
}
.your-work--icon {
  display: flex;
  flex: 0 0 56px;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-radius: 50%;
  background: #f2f2fc;
  margin-right: 28px;
}
@media (max-width: 768px) {
  .your-work--icon {
    margin-right: 24px;
  }
}
.your-work--icon svg {
  color: #4b3bff;
}
.your-work--copy {
  flex: 1 1 calc(100% - 84px);
}
.your-work--mobile-start {
  display: none;
}
@media (max-width: 768px) {
  .your-work--mobile-start {
    display: flex;
  }
}
.three-wide--top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .three-wide--top {
    margin-bottom: 32px;
  }
}
.three-wide--top h2 {
  flex-basis: 50%;
}
@media (max-width: 768px) {
  .three-wide--top h2 {
    flex-basis: 100%;
  }
}
.three-wide--top p {
  flex-basis: 50%;
  padding-left: 64px;
  color: #2f2f38;
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  letter-spacing: -0.02em;
}
@media (max-width: 768px) {
  .three-wide--top p {
    display: none;
  }
}
.three-wide--bottom {
  display: flex;
  margin-top: 40px;
  column-gap: 66px;
}
@media (max-width: 768px) {
  .three-wide--bottom {
    flex-direction: column;
    margin-top: 32px;
    row-gap: 40px;
  }
}
.three-wide--bottom div {
  flex-basis: 33.3%;
}
@media (max-width: 768px) {
  .three-wide--bottom div {
    flex-basis: 100%;
  }
}
.three-wide--bottom h3 {
  margin-bottom: 8px;
}
.three-wide--bottom p {
  color: #383945;
}
.two-wide {
  display: flex;
}
@media (max-width: 768px) {
  .two-wide {
    flex-direction: column;
  }
}
.two-wide h2 {
  margin-bottom: 68px;
}
@media (max-width: 768px) {
  .two-wide h2 {
    margin-bottom: 32px;
  }
}
.two-wide h3 {
  margin-bottom: 8px;
}
.two-wide p {
  margin-bottom: 56px;
  color: #383945;
}
@media (max-width: 768px) {
  .two-wide p {
    margin-bottom: 40px;
  }
}
.two-wide p:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .two-wide img {
    margin-bottom: 32px;
  }
}
.two-wide--right {
  max-width: 375px;
}
.two-wide--left {
  flex-basis: 60%;
  column-gap: 140px;
  margin-right: 48px;
}
@media (max-width: 768px) {
  .two-wide--left {
    flex-basis: 100%;
    margin: 0;
  }
}
.everyones-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .everyones-welcome {
    align-items: flex-start;
  }
}
.everyones-welcome svg {
  color: #4b3bff;
  margin-bottom: 24px;
}
.everyones-welcome h2 {
  font-size: 3.5rem;
  margin-bottom: 56px;
}
@media (max-width: 768px) {
  .everyones-welcome h2 {
    font-size: 2rem;
    margin-bottom: 32px;
  }
}
.everyones-welcome h3 {
  margin-bottom: 8px;
  color: #131215;
}
.everyones-welcome--bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 104px;
}
@media (max-width: 768px) {
  .everyones-welcome--bottom {
    flex-direction: column;
    row-gap: 40px;
  }
}
.blog-index-page {
  max-width: 800px;
}
.blog-index-page h2 {
  font-size: 2rem;
  margin: 48px 0 8px;
}
@media (max-width: 768px) {
  .blog-index-page h2 {
    font-size: 1.5rem;
  }
}
.blog-index-page h2 a {
  text-decoration: none;
  font-weight: 600;
  color: #2f2f38;
}
.blog-index-page--breadcrumbs {
  margin-bottom: 12px;
}
.blog-index-page--date {
  color: #50505e;
}
.blog-index-page--summary {
  margin: 16px 0;
  color: #2f2f38;
}
.blog-page {
  max-width: 800px;
  display: flex;
  flex-direction: column;
}
.blog-page--breadcrumbs {
  margin-bottom: 12px;
}
.blog-page--date {
  color: #50505e;
}
.blog-page--mailchimp-form {
  background: #f4f4f5;
  padding: 16px;
  border-radius: 16px;
  margin-top: 56px;
  width: fit-content;
  align-self: center;
}
@media (max-width: 768px) {
  .blog-page--mailchimp-form {
    max-width: 100%;
  }
}
.blog-page--mailchimp-form div {
  margin-bottom: 8px;
}
.blog-page--mailchimp-form form {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  row-gap: 8px;
}
@media (max-width: 768px) {
  .blog-page--mailchimp-form form {
    flex-direction: column;
  }
}
.help-index-page h1 {
  text-align: center;
}
@media (max-width: 768px) {
  .help-index-page h1 {
    text-align: left;
  }
}
.help-index-page p {
  margin: 16px 0 0 0;
  color: #2f2f38;
  text-align: center;
}
@media (max-width: 768px) {
  .help-index-page p {
    text-align: left;
  }
}
.help-index-page hr {
  border: 0;
  border-top: 1px solid #e9e9ea;
  margin: 64px 0;
}
.help-index-page h3 {
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  letter-spacing: -0.02em;
  font-weight: 600;
  margin-bottom: 12px;
  color: #2f2f38;
  font-size: 1.25rem;
}
.help-index-page h3 a {
  color: #000000;
}
.help-index-page section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .help-index-page section {
    flex-direction: column;
  }
}
.help-index-page div {
  width: calc(100% / 3);
  margin-bottom: 48px;
}
@media (max-width: 768px) {
  .help-index-page div {
    width: 100%;
  }
}
.help-index-page li {
  margin-bottom: 12px;
  color: #131215;
  line-height: 24px;
}
.help-page {
  max-width: 800px;
}
.help-page--breadcrumbs {
  margin-bottom: 12px;
}
.login-page {
  max-width: 425px;
  width: 100%;
}
@media (max-width: 768px) {
  .login-page {
    width: 100%;
    max-width: 100%;
  }
}
.login-page--subtitle {
  margin: 16px 0 32px;
}
button {
  margin-top: 16px;
}
.pricing-page {
  max-width: 1280px;
}
.pricing-page hr {
  margin: 64px 0;
  width: 100%;
  border: 0;
  border-top: 1px solid #e9e9ea;
}
.pricing-page h1 {
  font-size: 3.5rem;
  line-height: 64px;
}
@media (max-width: 768px) {
  .pricing-page h1 {
    font-size: 3rem;
    line-height: 56px;
  }
}
.pricing-page h2 {
  font-family:
    Biotif,
    system-ui,
    sans-serif;
  font-size: 2.5rem;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #131215;
  margin-bottom: 32px;
}
.pricing-page--prices-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}
.pricing-page--prices {
  display: flex;
  flex-direction: row;
  margin: 32px 0;
  column-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap;
}
@media (max-width: 1100px) {
  .pricing-page--prices {
    max-width: 724px;
  }
}
@media (max-width: 768px) {
  .pricing-page--prices {
    flex-direction: column;
  }
}
.pricing-page--price {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  flex-basis: 0;
  border: 1px solid #dfdfe1;
  border-radius: 12px;
  padding: 20px;
  max-width: 350px;
}
@media (max-width: 768px) {
  .pricing-page--price {
    max-width: 100%;
  }
}
.pricing-page--price[data-highlight=true] {
  border: 4px solid #4b3bff;
}
.pricing-page--price[data-highlight=true] .pricing-page--price-title {
  color: #4b3bff;
}
.pricing-page--price a.button {
  margin-top: 20px;
}
.pricing-page--price-title {
  color: #131215;
  margin-bottom: 4px;
}
.pricing-page--price-subtitle {
  color: #2f2f38;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #e9e9ea;
}
.pricing-page--price-check {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  column-gap: 8px;
  margin-bottom: 20px;
}
.pricing-page--price-check svg {
  flex-basis: 24px;
  color: #4b3bff;
  margin-top: 4px;
}
.pricing-page--price-check p {
  flex: 1;
  color: #131215;
}
.pricing-page--price-spacer {
  flex: 1;
}
@media (max-width: 1100px) {
  .pricing-page--questions {
    text-align: center;
  }
}
.pricing-page--faq {
  max-width: 800px;
}
.pricing-page--faq-q {
  color: #131215;
  margin-bottom: 8px;
}
.pricing-page--faq-a {
  margin-bottom: 40px;
}
.signup-page {
  max-width: 425px;
  width: 100%;
}
@media (max-width: 768px) {
  .signup-page {
    width: 100%;
    max-width: 100%;
  }
}
.signup-page button {
  margin-top: 16px;
}
.signup-page--subtitle {
  margin: 16px 0 32px 0;
}
.signup-page--fields {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
html,
body {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  background: #ffffff;
}
a {
  color: #4b3bff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
